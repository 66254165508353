// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
// eslint-disable-next-line no-extend-native
import {WEEK} from "@/constant";

Date.prototype.Format = function(fmt) {
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

export function formatTimeToStr(times, pattern) {
  var d = new Date(times).Format('yyyy-MM-dd hh:mm:ss')
  if (pattern) {
    d = new Date(times).Format(pattern)
  }
  return d.toLocaleString()
}

import { store } from '@/store'

export function formatTimeDiff(timeDiff) {
  if (timeDiff < 0) {
    timeDiff = 0
  }
  let dayStr = '天',
      hourStr = '小时',
      minStr = '分钟'
  if (store.state.user.lang !== 'zh') {
    dayStr = 'd '
    hourStr = 'h '
    minStr = 'min'
  }
  const day = 1000 * 60 * 60 * 24,
      hr =  1000 * 60 * 60,
      min = 1000 * 60
  let d = Math.floor(timeDiff / day)
  timeDiff = timeDiff - d * day
  let h = Math.floor(timeDiff / hr)
  timeDiff = timeDiff - h * hr
  let m = Math.floor(timeDiff / min)
  d = d > 0? d + dayStr : ''
  h = h > 0? h + hourStr : ''
  m = m? m + minStr : ''
  return d + h + m
}
