/* eslint-disable */
export const toUpperCase = (str) => {
    if (str[0]) {
        return str.replace(str[0], str[0].toUpperCase())
    } else {
        return ''
    }
}

export const toLowerCase = (str) => {
    if (str[0]) {
        return str.replace(str[0], str[0].toLowerCase())
    } else {
        return ''
    }
}

// 驼峰转换下划线
export const toSQLLine = (str) => {
    if (str === 'id') return 'id'
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}

// 下划线转换驼峰
export const toHump = (name) => {
    return name.replace(/\_(\w)/g, function(all, letter) {
        return letter.toUpperCase();
    });
}

export const objectToString = (o, deep) => {
    if (!deep) {
      deep = 0;
    }
    if (deep >= 2) {
      return o;
    }
    if(!o)
    {
      return "";
    }
    var r = [];
    if (typeof o == "string") {
      return (
        '"' +
        o
          .replace(/([\'\"\\])/g, "\\$1")
          .replace(/(\n)/g, "\\n")
          .replace(/(\r)/g, "\\r")
          .replace(/(\t)/g, "\\t") +
        '"'
      );
    }
    if (typeof o == "object") {
      if (!o.sort) {
        for (var i in o) {
          r.push(i + ":" + objectToString(o[i], deep + 1));
        }
        if (
          !!document.all &&
          !/^\n?function\s*toString\(\)\s*\{\n?\s*\[native code\]\n?\s*\}\n?\s*$/.test(
            o.toString
          )
        ) {
          r.push("toString:" + o.toString.toString());
        }
        r = "{" + r.join() + "}";
      } else {
        for (var i = 0; i < o.length; i++) {
          r.push(objectToString(o[i]));
        }
        r = "[" + r.join() + "]";
      }
      return r;
    }
    return o.toString();
  }

  
// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
export const getFormatDate = (fmt, date) =>  { // author: meizz
  var o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    "S": date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
}
export const getCurrentFormatDateString = () =>  { // author: meizz
  return getFormatDate("yyyy-MM-dd hh:mm:ss", new Date());
}